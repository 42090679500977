import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  useMediaQuery,
  makeStyles,
  createStyles,
  Modal,
  Backdrop,
  Fade
} from '@material-ui/core'
import {
  createWorkspace,
  createWorkspaceByTemplate
} from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import AsanaIcons from 'static/asana/AsanaIcons'
import {
  // CheckBoxField,
  FilePreview,
  LoadingButton
} from 'global/globalComponents'
import { getFileType, userRoles } from 'utils'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import { CustomModalFooter } from 'global/globalComponents/CustomModal'
import Currency from 'static/Currency/Currency'
import { useTranslation } from 'react-i18next'
import { gettingAllTasksStatus } from 'thunks/gettingStarted/actions'
import { CloseOutlined } from '@material-ui/icons'
import { uploadProjectFiles } from 'thunks/fileAssets/actions'
import ProjectFiles from './AddProjectModalFileUploads'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'
import SidePanel from './SidePanel'
import AddProjectTabs from './AddProjectTabs'
import FirstStepFields from './FirstStepFields'
import SecondStepFields from './SecondStepFields'
import ThirdStepFields from './ThirdStepFields'
import TemplateLibrary from 'components/Templates/TemplateLibrary'
import { useModal } from 'customHooks'
import { fetchAllProjectTemplates } from 'thunks/projectTemplate/action'
import clsx from 'clsx'

const AddProjectModal = ({ open, onClose, ...rest }) => {
  const classes = useStyles()

  return (
    <Modal
      style={{ zIndex: 1250 }}
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <div
          className={clsx(
            'h-full rounded-3xl relative overflow-auto',
            classes.paper
          )}
        >
          <AddProjectModalContent
            onClose={onClose}
            classes={classes}
            {...rest}
          />
        </div>
      </Fade>
    </Modal>
  )
}

const AddProjectModalContent = ({
  onClose,
  classes,
  selectedClient,
  selectedProjectTemplate = {}
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedTemplate, setSelectedTemplate] = useState(
    selectedProjectTemplate
  )
  const { rootDir } = useSelector(state => state.filesAssets)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const meData = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data?.access)
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    startDate: '',
    dueDate: '',
    type: 'External',
    budgetedHours: '',
    assignedAgencyTeam: [],
    assignedClientTeam: selectedClient || {},
    cost: '',
    currency: 'USD',
    isRecurring: false,
    restrictDueDate: {
      dueDateChangeCounter: 0,
      isDueDateRestrict: false,
      totalDueDateChangeCounter: null
    },
    addAdmins: true,
    category: null,
    paymentTerm: 'timesheet', //timesheet, project
    taskSequenceStartFrom: '001'
  })

  const [allTemplatesData, setAllTemplatesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(Currency['USD'])
  const [projectStep, setProjectStep] = useState(1)
  const [projectFiles, setProjectFiles] = useState([])
  const [filePreviewModal, setFilePreviewModal] = useState({
    open: false,
    files: [],
    fileIndex: 0
  })
  const [isAddTeammatePanelOpen, setIsAddTeammatePanelOpen] = useState(false)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true)
  const {
    modal: templatesLibraryModal,
    openModal: openTemplatesLibraryModal,
    closeModal: closeTemplatesLibraryModal
  } = useModal()

  useEffect(() => {
    if (formData.name.trim()) {
      setIsNextBtnDisabled(false)
    }
  }, [formData.name])

  useEffect(() => {
    // NOTE: If addAdmins is set to true that means during project creation backend will add all the admins automatically
    // So to avoid duplication don't send teammate with the admin role
    if (formData.addAdmins) {
      setFormData(prev => ({
        ...prev,
        assignedAgencyTeam: prev.assignedAgencyTeam.filter(
          mate => mate.role !== userRoles.AGENCY_ADMIN
        )
      }))
    }
  }, [formData.addAdmins])

  useEffect(() => {
    fetchAllProjectTemplates(null, (res, err) => {
      setLoading(false)
      if (!err) {
        setAllTemplatesData(res)
      }
    })
  }, [])

  const handleCreateNewProject = e => {
    e.preventDefault()
    const wsIdx = userWorkspaces.data.findIndex(i => i.name === formData.name)

    if (wsIdx !== -1) {
      dispatch(fireErrorToaster(t('ERROR_DUPLICATE_PROJECT_NOT_ALLOWED')))
      return
    }

    const workspaceData = {
      ...formData,
      assignedAgencyTeam: formData.assignedAgencyTeam.map(i => i._id),
      assignedClientTeam: formData.assignedClientTeam._id
        ? [formData.assignedClientTeam._id]
        : [],
      type: formData.assignedClientTeam._id ? 'External' : 'Internal',
      category: formData.category?._id,
      image: Object.keys(AsanaIcons)[
        Math.floor(Math.random() * Object.keys(AsanaIcons).length)
      ],
      backgroundcolor:
        colorOptions[Math.floor(Math.random() * colorOptions.length)]
    }

    // if (meData.role >= userRoles.USER_CLIENT) {
    //   workspaceData.user = meData.agency?._id || meData.team?._id
    // }

    setLoading(true)

    if (selectedTemplate._id) {
      dispatch(
        createWorkspaceByTemplate(
          {
            ...workspaceData,
            templateId: selectedTemplate._id,
            taskSequenceStartFrom:
              formData.taskSequenceStartFrom === '' ||
              isNaN(formData.taskSequenceStartFrom)
                ? '001'
                : formData.taskSequenceStartFrom
          },
          createNewWorkspaceCallback
        )
      )
    } else {
      dispatch(
        createWorkspace(
          {
            ...workspaceData,
            taskSequenceStartFrom:
              formData.taskSequenceStartFrom === '' ||
              isNaN(formData.taskSequenceStartFrom)
                ? '001'
                : formData.taskSequenceStartFrom
          },
          (res, err) => {
            dispatch(gettingAllTasksStatus())
            createNewWorkspaceCallback(res, err)
          }
        )
      )
    }
  }

  const createNewWorkspaceCallback = (wsRes, wsErr) => {
    if (wsErr) {
      setLoading(false)
      dispatch(fireErrorToaster(wsRes))
    } else {
      if (projectFiles.length) {
        uploadFiles(wsRes)
      } else {
        workspaceCreationSuccess(wsRes)
      }
    }
  }

  const workspaceCreationSuccess = wsRes => {
    history.push(
      `${getUserPath(meData.role)}/workspace/${wsRes._id}/board/${
        wsRes.defaultBoard._id
      }?view=board`
    )
    // clearValue()
    onClose()
    dispatch(fireSuccessToaster(t('PROJECT_CREATED_SUCCESSFULLY')))
    setLoading(false)
  }

  const handleUpdateFormData = ({ field, value }) => {
    setFormData(prev => ({ ...prev, [field]: value }))
  }

  const handleAddNewTeammate = teammate => {
    setFormData(prev => ({
      ...prev,
      assignedAgencyTeam: [...prev.assignedAgencyTeam, teammate]
    }))
  }

  const media = useMediaQuery('(min-width:1024px)')

  const handlePreviewAttachment = useCallback((fileIndex, allFiles) => {
    const files = allFiles.map(fileObj => {
      const { file, url } = fileObj

      return {
        name: file.name,
        type: getFileType(file.name),
        extension: file.name.slice(file.name.lastIndexOf('.')),
        url
      }
    })

    setFilePreviewModal({ open: true, files, fileIndex })
  }, [])

  const uploadFiles = wsRes => {
    projectFiles.forEach((projectFile, index) => {
      const { file } = projectFile
      const fileData = {
        fileName: file.name.slice(0, file.name.lastIndexOf('.')),
        file: file
        // fileAssetsFolder: wsRes._id
      }
      handleUploadFile(fileData, wsRes._id, (res, err) => {
        //update file in projectFiles
        setProjectFiles(prev => {
          prev[index] = { ...prev[index], ...res }
          return prev
        })
        let isAllUploaded = !Boolean(
          projectFiles.filter(el => !el.isComplete || el.message || el.aborted)
            .length
        )
        if (err) {
          dispatch(fireErrorToaster(res.message))
        }
        if (isAllUploaded) {
          workspaceCreationSuccess(wsRes)
        }
      })
    })
  }

  const handleUploadFile = (fileData, workspaceId, callback) => {
    dispatch(
      uploadProjectFiles(
        {
          data: fileData,
          metadata: {
            fileAssetsFolder: rootDir,
            generalPermissions: [{ type: 'withProjectTeam', role: 'editor' }],
            workspace: workspaceId
          },
          parent: workspaceId
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
          callback(res, err)
        }
      )
    )
  }

  return (
    <>
      <div className="flex h-full dark:bg-dark-main2">
        {media && <SidePanel formData={formData} />}

        <div className="h-full flex-1 flex flex-col justify-between">
          <header className="flex justify-between items-center px-6 select-none rounded-t-3xl border-b border-gray-300 h-16 dark:text-dark-light2">
            <div className="flex gap-2 items-center">
              <div>
                <h2 className="text-lg 2xl:text-xl font-semibold text-primary-dark mr-8 whitespace-nowrap dark:text-dark-light2">
                  {t('NEW_PROJECT')}
                </h2>
              </div>
            </div>
            <div>
              <span className=" text-gray-600 cursor-pointer" onClick={onClose}>
                <CloseOutlined />
              </span>
            </div>
          </header>

          <div className="pt-5 px-6 flex-1 overflow-y-auto">
            <AddProjectTabs
              projectStep={projectStep}
              setProjectStep={setProjectStep}
            />

            {projectStep === 1 ? (
              <FirstStepFields
                formData={formData}
                handleUpdateFormData={handleUpdateFormData}
                selectedTemplate={selectedTemplate}
                handleSelectTemplate={setSelectedTemplate}
                openTemplatesSection={openTemplatesLibraryModal}
                allTemplatesData={allTemplatesData}
              />
            ) : projectStep === 2 ? (
              <SecondStepFields
                formData={formData}
                handleUpdateFormData={handleUpdateFormData}
              />
            ) : projectStep === 3 ? (
              <ThirdStepFields
                formData={formData}
                handleUpdateFormData={handleUpdateFormData}
                selectedCurrency={selectedCurrency}
                handleUpdateCurrency={setSelectedCurrency}
                classes={classes}
              />
            ) : (
              projectStep === 4 && (
                <ProjectFiles
                  projectFiles={projectFiles}
                  loading={loading}
                  setProjectFiles={setProjectFiles}
                  handlePreview={handlePreviewAttachment}
                />
              )
            )}
          </div>
          <CustomModalFooter>
            <Button
              size="large"
              variant="outlined"
              onClick={
                projectStep === 1
                  ? onClose
                  : () => setProjectStep(projectStep - 1)
              }
            >
              {projectStep === 1 ? t('CANCEL') : t('BACK')}
            </Button>
            {projectStep === 4 ? (
              <LoadingButton
                size="large"
                onClick={handleCreateNewProject}
                loading={loading}
                disabled={!permission?.workspace?.create}
              >
                {t('CREATE')}
              </LoadingButton>
            ) : (
              <Button
                size="large"
                onClick={() => setProjectStep(projectStep + 1)}
                disabled={isNextBtnDisabled}
              >
                {t('NEXT')}
              </Button>
            )}
          </CustomModalFooter>
        </div>
      </div>

      <FilePreview
        open={filePreviewModal.open}
        files={filePreviewModal.files}
        fileIndex={filePreviewModal.fileIndex}
        onClose={() => setFilePreviewModal(prev => ({ ...prev, open: false }))}
      />

      <AddNewTeammatePanel
        open={isAddTeammatePanelOpen}
        onClose={() => setIsAddTeammatePanelOpen(false)}
        metadata={{
          styles: {
            root: { zIndex: '1999 !important' },
            drawerPaper: { marginTop: 0, maxHeight: '100vh' }
          }
        }}
        callback={handleAddNewTeammate}
      />

      <TemplateLibrary
        open={templatesLibraryModal.open}
        onClose={closeTemplatesLibraryModal}
        onTemplateSelect={setSelectedTemplate}
        prefetchedTemplatesData={allTemplatesData}
      />
    </>
  )
}

const colorOptions = [
  '#ffffff',
  '#fb5779',
  '#ff7511',
  '#ffa800',
  '#ffd100',
  '#ace60f',
  '#19db7e',
  '#00d4c8',
  '#48dafd',
  '#0064fb',
  '#6457f9',
  '#9f46e4',
  '#ff78ff',
  '#ff4ba6',
  '#ff93af',
  '#5a7896'
]

const useStyles = makeStyles(theme => {
  const isDarkMode = theme.palette.type === 'dark'

  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
      // userSelect: 'none'
    },
    paper: {
      width: '80vw',
      height: '90vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none'
    },
    datePicker: {
      width: '100%',
      '& .react-datepicker': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark2 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      },
      '& .react-datepicker__header': {
        backgroundColor: isDarkMode ? theme.custom.darkMode.dark1 : '#fff',
        color: isDarkMode ? theme.custom.darkMode.color : '#000'
      }
    },
    fieldsContainer: {
      width: '100%',
      '@media (min-width: 768px)': {
        minWidth: '70%',
        flex: 1
      }
    }
  })
})

export default AddProjectModal
