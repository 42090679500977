import { TextField, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { generateEmbed } from 'thunks/embed/action'
import { useHistory } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import embedStyles from './EmbedStyles'
import { useTranslation } from 'react-i18next'
import QuestionMarkTooltip from 'global/globalComponents/QuestionMarkTooltip'

// TODO: Need to refactor this file
const EmbedModalUI = ({ id, name, image, description, handleClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = embedStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector(state => state.me)
  const { currentWorkspace } = useSelector(state => state.userWorkspaces)
  const [embedData, setEmbedData] = useState({ name: '', url: '' })
  const [loading, setLoading] = useState(false)
  const [taskSequence, setTaskSequence] = useState('001')
  const [displayTaskSequence, setDisplayTaskSequence] = useState(false)

  useEffect(() => {
    setEmbedData({ name: '', url: '' })
  }, [id])

  const saveEmbedLink = e => {
    e.preventDefault()
    if (!embedData.name) {
      return dispatch(fireErrorToaster(t('PLEASE_FILL_ALL_THE_FIELDS')))
    }

    if (id !== 'board' && !embedData.url) {
      return dispatch(fireErrorToaster(t('PLEASE_FILL_ALL_THE_FIELDS')))
    }

    setLoading(true)
    const data = {
      name: embedData.name,
      type: id,
      category: id === 'board' ? 'External' : currentWorkspace.type,
      workspace: currentWorkspace._id,
      embed_settings: { url: embedData.url }
    }

    if (id === 'board') {
      data.taskSequenceStartFrom =
        taskSequence === '' || isNaN(taskSequence) ? '001' : taskSequence
      data.displayTaskSequence = displayTaskSequence
    }

    if (
      embedData.url &&
      !(
        embedData.url.startsWith('https://') ||
        embedData.url.startsWith('http://')
      )
    ) {
      data.embed_settings.url = `https://${embedData.url}`
    }

    dispatch(generateEmbed({ data }, generateEmbedCallback))
  }

  const generateEmbedCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      handleClose()
      history.push(
        `${getUserPath(meData.data?.role)}/workspace/${
          currentWorkspace?._id
        }/${name.toLowerCase().replace(' ', '')}/${res._id}${
          res.type === 'board' ? '?view=board' : ''
        }`
      )
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const updateTaskSequence = e => {
    const value = parseInt(e.target.value)

    if (e.target.value === '' || (value >= 0 && value <= 999)) {
      setTaskSequence(e.target.value)
    }
  }

  return (
    <div className={classes.area}>
      <div className={classes.image}>{image}</div>
      <div className="px-8">
        <h3 className="text-base 2xl:text-lg text-left font-normal pb-2 text-gray-700 dark:text-dark-light2">
          {name}
        </h3>
        <p className="text-smaller 2xl:text-sm text-left font-normal pb-2 text-gray-600 dark:text-dark-light">
          {description}
        </p>
        <form onSubmit={saveEmbedLink}>
          {id !== 'board' && (
            <div>
              <p className="text-left text-sm font-base pb-2 text-gray-700 dark:text-dark-light">
                {name}
              </p>
              <TextField
                required
                className="w-full"
                style={{
                  backgroundColor:
                    theme.palette.type === 'light'
                      ? '#fff'
                      : theme.custom.darkMode.dark1
                }}
                value={embedData.url}
                placeholder={t('ENTER_URL')}
                onChange={e =>
                  setEmbedData(prev => ({ ...prev, url: e.target.value }))
                }
              />
            </div>
          )}
          <div className="mt-2">
            <p className="text-left text-smaller 2xl:text-sm font-base pb-2 text-gray-700 dark:text-dark-light">
              {name} {t('NAME')}
            </p>
            <TextField
              required
              className="w-full"
              style={{
                backgroundColor:
                  theme.palette.type === 'light'
                    ? '#fff'
                    : theme.custom.darkMode.dark1
              }}
              value={embedData.name}
              placeholder={t('ENTER_NAME')}
              onChange={e =>
                setEmbedData(prev => ({ ...prev, name: e.target.value }))
              }
            />
          </div>

          {id === 'board' && (
            <>
              <div className="mt-2">
                <p className="text-left text-smaller 2xl:text-sm font-base pb-2 text-gray-700 dark:text-dark-light">
                  {t('SET_TASK_SEQUENCE')}
                </p>
                <TextField
                  className="w-full"
                  type="number"
                  value={taskSequence}
                  onChange={updateTaskSequence}
                  placeholder="0-999"
                />
              </div>

              <div className="flex items-center mt-2">
                <p className="text-left text-smaller 2xl:text-sm text-gray-700 dark:text-dark-light">
                  {t('DISPLAY_SEQUENCE_ID')}
                </p>
                <div className="-mt-1 ml-1">
                  <QuestionMarkTooltip text={t('DISPLAY_SEQUENCE_ID_INFO')} />
                </div>
                <div className="ml-4">
                  <ToggleSwitch
                    checked={displayTaskSequence}
                    onChange={e => setDisplayTaskSequence(e.target.checked)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end items-center mt-8">
            <LoadingButton
              type="submit"
              loading={loading}
              className="dark:bg-dark-main1"
            >
              {t('ADD_VIEW')}
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmbedModalUI
