import { combineReducers } from 'redux'
import { fireToaster } from 'thunks/fireToaster/reducers'
import { loginReducer } from 'thunks/login/reduers'
import meReducer from 'thunks/me/reducers'
import userReducer from 'thunks/user/reducers'
import teammatesReducer from 'thunks/teammate/reducers'
import workspacesReducer from 'thunks/workspace/reducers'
import tagReducer from 'thunks/tags/reducers'
import integrationsReducer from 'thunks/integration/reducers'
import tasksReducer from 'thunks/task/reducers'
import serviceReducer from 'thunks/service/reducers'
import clientReducer from 'thunks/addClient/reducers'
import fetchAgencyPlansReducer from 'thunks/agencyPlan/reducers'
import subTaskReducer from 'thunks/subTask/reducers'
import generalAppStateReducer from 'thunks/generlAppState/reducers'
import fileAssetReducer from 'thunks/fileAssets/reducers'
import stripeInfoReducer from 'thunks/stripeAccount/reducers'
import userActivePlanReducer from 'thunks/userActivePlanInfo/reducers'
import logsReducer from 'thunks/logs/reducer'
import webhookReducer from 'thunks/webhooks/reducer'
import breadcrumbsReducer from 'thunks/breadcrumbs/reducer'
import agencyPaymentReducer from 'thunks/agencyPayment/reducers'
import emailNotificationEventsReducer from 'thunks/emailNotificationEvents/reducer'
import clientInvoicesReducer from 'thunks/invoices/reducer'
import gettingStartedTasksReducer from 'thunks/gettingStarted/reducers'
import permissionReducer from 'thunks/permission/reducer'
import cancelClientServiceRequest from 'thunks/cancelClientServiceRequest/reducers'
import onboardingReducer from 'thunks/onboarding/reducer'
// import clientRaiseRequestReducer from 'thunks/clientRaiseRequest/reducer'
// import clientRaisedRequestAgencyReducer from 'thunks/clientRaisedRequestAgency/reducer'
import embedReducer from 'thunks/embed/reducer'
import userDashboard from 'thunks/userDashboard/reducer'
import accountLogs from 'thunks/accountLogs/reducers'
import userPermissionReducer from 'thunks/userPermissions/reducers'
import verticalsReducer from 'thunks/Verticals/reducer'
import clientCompanyReducer from 'thunks/clientCompany/reducer'
import widgetReducer from 'thunks/widget/reducer'
import viewsReducer from 'thunks/views/reducer'
import timerReducer from 'thunks/Tracking/reducer'
import reportsReducer from 'thunks/reports/reducer'
import standupsReducer from 'thunks/standups/reducer'
import pulseReducer from 'thunks/pulse/reducer'
import reportingReducer from 'thunks/StatusPost/reducer'
import calendarReducer from 'thunks/calender/reducer'
import channelsReducer from 'thunks/chat/reducer'
import feedReducer from 'thunks/feeds/reducer'
import { chatMessengerDestroy } from 'utils/manageChatMessenger'
import reportsForClientReducer from 'thunks/ReportsForClient/reducer'
import approvalReducer from 'thunks/approvals/reducer'
import serviceMarketplaceReducer from 'thunks/serviceMarketplace/reducer'
import couponReducer from 'thunks/coupon/reducer'
import clientRequestReducer from 'thunks/clientRequests/reducer'
import subscriptionsReducer from 'thunks/subscription/reducer'

// Note: Add them in alphabetical order (only the first letter)
const appReducer = combineReducers({
  accountLogs: accountLogs,
  agencyPayment: agencyPaymentReducer,
  approvals: approvalReducer,
  appToaster: fireToaster,
  agencyPlans: fetchAgencyPlansReducer,
  breadcrumbs: breadcrumbsReducer,
  clientInvoices: clientInvoicesReducer,
  clientCompany: clientCompanyReducer,
  cancelClientServiceRequest: cancelClientServiceRequest,
  clientRequests: clientRequestReducer,
  coupons: couponReducer,
  dashboard: userDashboard,
  emailNotificationEvents: emailNotificationEventsReducer,
  filesAssets: fileAssetReducer,
  gettingStartedTasks: gettingStartedTasksReducer,
  generalAppState: generalAppStateReducer,
  login: loginReducer,
  logs: logsReducer,
  me: meReducer,
  onboarding: onboardingReducer,
  pulse: pulseReducer,
  permission: permissionReducer,
  reportsForClient: reportsForClientReducer,
  reports: reportsReducer,
  subTasks: subTaskReducer,
  standups: standupsReducer,
  subscriptions: subscriptionsReducer,
  timer: timerReducer,
  teamVerticals: verticalsReducer,
  user: userReducer,
  userTeammates: teammatesReducer,
  userWorkspaces: workspacesReducer,
  userTags: tagReducer,
  embed: embedReducer,
  userReportings: reportingReducer,
  userIntegrations: integrationsReducer,
  userTasks: tasksReducer,
  userServices: serviceReducer,
  userClients: clientReducer,
  userStripeInfo: stripeInfoReducer,
  userActivePlanInfo: userActivePlanReducer,
  userWebhooks: webhookReducer,
  userPermission: userPermissionReducer,
  views: viewsReducer,
  calendar: calendarReducer,
  chats: channelsReducer,
  // workspacePermission: workspacePermissionReducer,
  widget: widgetReducer,
  feeds: feedReducer,
  serviceMarketplace: serviceMarketplaceReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT' && !state.login.agencyPreview) {
    chatMessengerDestroy()

    // Reset all data except generalAppState
    return {
      ...appReducer(undefined, action),
      generalAppState: state.generalAppState
    }
  }

  return appReducer(state, action)
}

export default rootReducer
