import axios from 'axios'
import { WORKSPACES } from 'thunks/workspace/reducers'
import {
  Uploader,
  userRoles,
  getQueryString,
  catchAsync,
  catchAsyncDispatch
} from 'utils'
import { TASKS } from './reducers'
import {
  getDueDateSectionId,
  getAssigneeSectionId
} from 'components/Boards/BoardView/boardSectionsHelper'

const getReqDataWithFilters = data => {
  return data.filters
    ? data
    : {
        ...data,
        filters: {
          op: 'AND',
          fields: []
        }
      }
}

/**
 * Fetching Unscheduled Tasks
 * @param {Object} data {workspace,workspaceBoard,filters,myApprovals,startDate,endDate,search}
 * @param {Object} query {type,limit,page}
 */
export const fetchUnscheduledTasks = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=calendar&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: data.data
    })
    dispatch({ type: TASKS.FETCHED_UNSCHEDULED_TASKS, payload: res.data })
  }, callback)
}

/**
 * Fetching OverDue Tasks
 * @param {Object} data {workspace,workspaceBoard,filters,myApprovals,startDate,endDate,search}
 * @param {Object} query {type,limit,page}
 */
export const fetchOverDueTasks = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=calendar&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: data.data
    })
    dispatch({ type: TASKS.FETCHED_OVERDUE_TASKS, payload: res.data })
  }, callback)
}

/**
 * Appending Unscheduled Tasks on click of Load More
 * @param {Object} data {workspace,workspaceBoard,filters,myApprovals,startDate,endDate,search}
 * @param {Object} query {type,limit,page}
 */
export const fetchMoreUnscheduledTasks = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=calendar&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: data.data
    })
    dispatch({
      type: TASKS.FETCHED_MORE_UNSCHEDULED_TASKS,
      payload: res.data
    })
  }, callback)
}

/**
 * Appending Overdue Tasks on click of Load More
 * @param {Object} data {workspace,workspaceBoard,filters,myApprovals,startDate,endDate,search}
 * @param {Object} query {type,limit,page}
 */
export const fetchMoreOverdueTasks = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=calendar&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: data.data
    })
    dispatch({ type: TASKS.FETCHED_MORE_OVERDUE_TASKS, payload: res.data })
  }, callback)
}
/**
 * Create a new task
 * @param {Object} data.data required fields - title, workSpace, workspaceBoard, taskSection
 * @param {Function} callback
 */
export const createTask = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { userWorkspaces } = getState()

    const res = await axios({
      method: 'POST',
      url: '/task',
      data: data.data
    })

    const dueDateSectionId = getDueDateSectionId(res.data.dueDate)
    const assigneeSectionId = getAssigneeSectionId([
      ...res.data.assignedAgencyTeam,
      ...res.data.assignedClientTeam
    ])

    dispatch({
      type: TASKS.ADD_ONE_TASK,
      payload: {
        task: res.data,
        assigneeSectionId,
        dueDateSectionId,
        sequenceId: userWorkspaces.currentBoard?.taskSequenceStartFrom
      }
    })

    if (callback) callback(res.data)

    const currentWorkspace = userWorkspaces.data.find(
      item => item._id === res.data.workSpace
    )

    dispatch({
      type: WORKSPACES.UPDATE_ONE_WORKSPACE,
      payload: {
        ...currentWorkspace,
        totalTask: (currentWorkspace.totalTask ?? 0) + 1
      }
    })
  }, callback)
}

/**
 * Fetch all tasks of a particular section
 * @param {Object} data.data workspace, workspaceBoard, section, myApprovals(optional), search(optional)
 * @param {Object} data.query view, type, limit, page
 * @param {String} data.sectionId
 * @param {String} data.groupBy (status, dueDate, assignee)
 * @param {Function} callback callback function
 */
export const getSectionTasks = (data, callback) => {
  // If data doesn't have filters key then adding default filters value
  const dataWithFilters = getReqDataWithFilters(data.data)

  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section${getQueryString(data.query)}`,
      data: dataWithFilters
    })

    dispatch({
      type:
        data.query.page === 0
          ? TASKS.FETCHED_SECTION_TASKS
          : TASKS.LOAD_MORE_SECTION_TASKS,
      payload: {
        sectionId: data.sectionId,
        tasks: res.data,
        groupBy: data.groupBy
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch group by assignee sections
 * @param {String} data.boardId board id
 * @param {Function} callback callback function
 */
export const getAssigneeSections = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/task/board/assignee/section/${data.boardId}`
  })

  if (callback) callback(res.data)
})

/**
 * Fetch all tasks of list view
 * @param {Object} data workspace, workspaceBoard, myApprovals
 * @param {Object} query type, limit, page
 * @param {Function} callback callback function
 */
export const getListViewTasks = (data, callback) => {
  // If data doesn't have filters key then adding default filters value
  const dataWithFilters = getReqDataWithFilters(data.data)

  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=list&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: dataWithFilters
    })
    if (callback) callback(res.data)
    dispatch({
      type:
        data.query.page === 0
          ? TASKS.FETCHED_LIST_VIEW_TASKS
          : TASKS.LOAD_MORE_LIST_VIEW_TASKS,
      payload: res.data
    })
  }, callback)
}

/**
 * Fetch all tasks of calendar view
 * @param {Object} data workspace, workspaceBoard, startDate, endDate, myApprovals
 * @param {Object} query type, limit, page
 * @param {Function} callback callback function
 */
export const getCalendarViewTasks = (data, callback) => {
  // If data doesn't have filters key then adding default filters value
  const dataWithFilters = getReqDataWithFilters(data.data)

  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'POST',
      url: `/task/section?view=calendar&type=${data.query.type}&limit=${data.query.limit}&page=${data.query.page}`,
      data: dataWithFilters
    })

    dispatch({
      type: TASKS.FETCHED_CALENDAR_VIEW_TASKS,
      payload: res.data
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Move task to trash
 * @param {string} Id task id
 * @param {Function} callback callback function
 */

export const moveTaskToTrash = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { userWorkspaces, userTasks, me } = getState()
    const taskData = userTasks.data[data.taskId]

    const res = await axios({
      method: 'PUT',
      url: `/task/${data.taskId}`,
      data: {
        isDeleted: true,
        deletedOn: new Date().toISOString(),
        deletedBy: me.data._id,
        workSpace: taskData.workSpace,
        taskSection: taskData.taskSection._id
      }
    })

    const dueDateSectionId = getDueDateSectionId(res.data.dueDate)
    const assigneeSectionId = getAssigneeSectionId([
      ...res.data.assignedAgencyTeam,
      ...res.data.assignedClientTeam
    ])

    dispatch({
      type: TASKS.DELETE_ONE_TASK,
      payload: { task: res.data, dueDateSectionId, assigneeSectionId }
    })

    const currentWorkspace = userWorkspaces.data.find(
      item => item._id === res.data.workSpace
    )
    dispatch({
      type: WORKSPACES.UPDATE_ONE_WORKSPACE,
      payload: {
        ...currentWorkspace,
        totalTask: (currentWorkspace.totalTask || 1) - 1,
        completedTask: res.data.markAsComplete
          ? (currentWorkspace.completedTask || 1) - 1
          : currentWorkspace.completedTask ?? 0
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete a task
 * @param {string} Id task id
 * @param {Function} callback callback function
 */

export const deleteTaskByID = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { userWorkspaces } = getState()
    const res = await axios({
      method: 'DELETE',
      url: `/task/${data.taskId}`
    })

    const dueDateSectionId = getDueDateSectionId(res.data.dueDate)
    const assigneeSectionId = getAssigneeSectionId([
      ...res.data.assignedAgencyTeam,
      ...res.data.assignedClientTeam
    ])

    dispatch({
      type: TASKS.DELETE_ONE_TASK,
      payload: { task: res.data, dueDateSectionId, assigneeSectionId }
    })

    const currentWorkspace = userWorkspaces.data.find(
      item => item._id === res.data.workSpace
    )

    //  if workspace to the task is in trash, it's not included in userWorkspaces.data, and hence currentWorkspace may not be found
    if (currentWorkspace)
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE,
        payload: {
          ...currentWorkspace,
          totalTask: (currentWorkspace.totalTask || 1) - 1,
          completedTask: res.data.markAsComplete
            ? (currentWorkspace.completedTask || 1) - 1
            : currentWorkspace.completedTask ?? 0
        }
      })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete a teammate from a task
 * @param {String} task_id task id
 * @param {Object} data teammate data
 * @param {Function} callback callback function
 */
export const deleteTaskDetailsTeam = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/deleteteammate/${data.taskId}`,
      data: data.data
    })
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Admin and super admin will receive all tasks while other users will receive only priority tasks
 * @param {Function} callback callback function that
 */
export const getPriorityTasksNew = (data, callback) => {
  const dataWithFilters = getReqDataWithFilters(data.data)

  return catchAsyncDispatch(async dispatch => {
    dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    const res = await axios({
      method: 'POST',
      url: `/task/prioritytask${getQueryString(data.query)}`,
      data: dataWithFilters
    })

    if (callback) callback(res.data)

    dispatch({
      type:
        data.query.page === 0
          ? TASKS.FETCHED_PRIORITY_TASKS
          : TASKS.LOAD_MORE_PRIORITY_TASKS,
      payload: res.data
    })
  }, callback)
}

/**
 * Get particular user's tasks (no redux update)
 * @param {Function} callback callback function that
 */
export const getUserTasks = catchAsync(async (data, callback) => {
  const dataWithFilters = getReqDataWithFilters(data.data)
  const res = await axios({
    method: 'POST',
    url: `/task/prioritytask${getQueryString(data.query)}`,
    data: dataWithFilters
  })

  if (callback) callback(res.data)
})

/**
 * Fetch single task details by id
 * @param {String} taskId task id
 * @param {Function} callback callback function
 */
export const fetchTaskById = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'GET',
      url: `/task/${data.taskId}`
    })

    if (callback) callback(res.data)

    const dueDateSectionId = getDueDateSectionId(res.data.dueDate)
    const assigneeSectionId = getAssigneeSectionId([
      ...res.data.assignedAgencyTeam,
      ...res.data.assignedClientTeam
    ])

    dispatch({
      type: TASKS.ADD_ONE_TASK,
      payload: { task: res.data, dueDateSectionId, assigneeSectionId }
    })
  }, callback)
}

/**
 * Fetch single task details by id (It doesn't update redux)
 * @param {String} taskId task id
 * @param {Function} callback callback function
 */
export const fetchSingleTaskById = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/task/${data.taskId}`
  })

  if (callback) callback(res.data)
})

/**
 * Update task data
 * @param {String} data.taskId
 * @param {Object} data.data Task object
 * @param {Function} callback callback function
 */
export const updateTaskDetails = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { userWorkspaces } = getState()
    const res = await axios({
      method: 'PUT',
      url: `/task/${data.taskId}`,
      data: data.data
    })
    if (callback) callback(res.data, false)

    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })

    const currentWorkspace = userWorkspaces.data.find(
      item => item._id === res.data.workSpace
    )
    if (!currentWorkspace) return

    if (data.data.field === 'markAsComplete') {
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE,
        payload: {
          ...currentWorkspace,
          completedTask:
            (currentWorkspace.completedTask ?? 0) +
            (data.data.markAsComplete ? 1 : -1)
        }
      })
    } else if (data.data.field === 'dueDate' && !res.data.markAsComplete) {
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE,
        payload: {
          ...currentWorkspace,
          overdueTask: res.data.dueDate
            ? new Date() > new Date(res.data?.dueDate)
              ? (currentWorkspace.overdueTask ?? 0) + 1
              : currentWorkspace.overdueTask
            : (currentWorkspace.overdueTask ?? 1) - 1
        }
      })
    }
  }, callback)
}

export const updateTaskSection = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/${data.taskId}`,
      data: data.data
    })

    if (callback) callback(res.data, false)
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

export const updateTaskPosition = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/position/${data.taskId}`,
      data: data.data
    })
    dispatch({ type: TASKS.UPDATE_POSITION, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * update a task assigned team
 * @param {String} taskId task id
 * @param {Object} data  assigned team id
 * @param {Function} callback callback function
 */

export const updateTaskDetailsTeam = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/addteammate/${data.taskId}`,
      data: data.data
    })
    if (callback) callback(res.data, false)
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

/**
 * Update task data
 * @param {String} taskId task id
 * @param {{}} data Task object
 * @param {Function} callback callback function
 */
export const updateTaskPriority = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/taskPriority/${data.taskId}`,
      data: data.data
    })
    if (callback) callback(res.data, false)
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

// /**
//  * @typedef {Object} Point
//  * @property {String} workSpace - Current workspace id
//  * @property {String} task - task id
//  * @property {String} file - File in base64 format
//  */

/**
 * upload a task attachment
 * @param {Object} fileData fileName, file
 * @param {Object} data Task data
 * @param {String} data.workSpace workSpace id
 * @param {String} data.task task id
 * @param {String} data.file file in base64 format
 * @param {Function} callback callback function
 */
export const uploadAttachment = (data, callback) => {
  return dispatch => {
    const uploader = new Uploader({
      ...data.fileData,
      module: 'task',
      metaData: data.data
    })

    uploader
      .onError(error => {
        callback(error.message ?? 'File uploading failed!', true)
      })
      .onComplete(res => {
        // here res object has 2 keys => link (image url), data: (task data)
        callback(res)
        dispatch({ type: TASKS.UPLOAD_ATTACHMENT, payload: res.data })
      })

    uploader.start()
  }
}

/**
 * delete a task attachment
 * @param {String} id task id
 * @param {Object} url  attachment url
 * @param {Function} callback callback function
 */
export const deleteAttachment = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/task/attachment?task=${data.taskId}&url=${data.url}`
    })
    if (callback) callback(res.data, false)
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

/**
 * fetch all logs of task and subtask
 * @param {String} data.type task/subTask
 * @param {String} data.taskId task id
 * @param {String} data.page task id
 * @param {Function} callback callback function
 */
export const fetchTaskLogs = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/logs?type=${data.type}&${data.type}=${data.taskId}&page=${data.page}`
  })
  if (callback) callback(res.data, false)
})

// /**
//  * update task sharing link
//  * @param {String} id subTask id
//  * @param {{}} taskId  sharing
//  * @param {Function} callback callback function
//  */
// export const shareTask = (id, data, callback) => {
//   return async dispatch => {
//     try {
//       const res = await axios({
//         method: 'PUT',
//         url: `task/updatesharing/${id}`,
//         data: data
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       dispatch({
//         type: TASKS.ERROR,
//         payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
//       })
//     }
//   }
// }

export const deleteOneTaskByPusher = data => {
  return (dispatch, getState) => {
    const { userTasks } = getState()

    if (userTasks.data[data.task._id]) {
      const dueDateSectionId = getDueDateSectionId(data.task)
      const assigneeSectionId = getAssigneeSectionId([
        ...data.task.assignedAgencyTeam,
        ...data.task.assignedClientTeam
      ])

      dispatch({
        type: TASKS.DELETE_ONE_TASK,
        payload: { task: data.task, dueDateSectionId, assigneeSectionId }
      })
    }
  }
}

export const addOneTaskByPusher = data => {
  return (dispatch, getState) => {
    const { userTasks, userWorkspaces, me } = getState()
    const { currentWorkspace, currentBoard } = userWorkspaces
    const meData = me.data

    const addTask = () => {
      if (!userTasks.data[data.task._id]) {
        const dueDateSectionId = getDueDateSectionId(data.task.dueDate)
        const assigneeSectionId = getAssigneeSectionId([
          ...data.task.assignedAgencyTeam,
          ...data.task.assignedClientTeam
        ])

        dispatch({
          type: TASKS.ADD_ONE_TASK,
          payload: { task: data.task, dueDateSectionId, assigneeSectionId }
        })
      }
    }

    // No need to add task if the currentWorkspace is different
    if (
      data.task.workSpace !== currentWorkspace._id ||
      data.task.workspaceBoard !== currentBoard._id
    )
      return

    // If current workspace has restrict mode ON then add tasks conditionally
    // Bcoz we don't want agency viewer and editor to see the task recently added
    if (
      meData.role === userRoles.USER_AGENCY ||
      meData._id === userRoles.AGENCY_ADMIN ||
      meData._id === data.task.user._id
    ) {
      addTask()
      return
    }

    if (!currentWorkspace?.restrictMode) {
      addTask()
      return
    }

    const teammateObj = [
      ...currentWorkspace.assignedAgencyTeam,
      ...currentWorkspace.assignedClientTeam
    ].find(item => item.user._id === meData._id)

    if (teammateObj) {
      const workspaceRole = teammateObj.role

      if (
        workspaceRole !== userRoles.AGENCY_EDITOR &&
        workspaceRole !== userRoles.AGENCY_VIEWER
      ) {
        addTask()
      }
    }
  }
}

export const pusherUpdateOneTask = data => {
  return (dispatch, getState) => {
    const { userTasks, me } = getState()

    if (userTasks.data[data.task._id]) {
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: data.task })
    }

    if (me.data.role !== userRoles.USER_AGENCY) {
      const myTask = [
        ...data.task.assignedAgencyTeam,
        ...data.task.assignedClientTeam
      ].find(item => item._id === me.data._id)

      dispatch({
        type: myTask
          ? TASKS.ADD_ONE_PRIORITY_TASK
          : TASKS.REMOVE_ONE_PRIORITY_TASK,
        payload: data.task
      })
    }
  }
}

/**
 * Create new column in task table
 * @param {Object} data.data name, board, workspace, datatypeId
 * @param {Function} callback callback function
 */
export const createNewColumn = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'POST',
    url: '/tableColumn',
    data: data.data
  })

  callback(res.data)
})

/**
 * Fetch all task table columns
 * @param {String} data.boardId board id
 * @param {Function} callback callback function
 */
export const fetchTaskTableColumns = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: `/tablecolumn/board/${data.boardId}`
  })

  callback(res.data)
})

/**
 * delete task column (table view)
 * @param {String} data.columnId column id
 * @param {Function} callback callback function
 */
export const deleteTaskColumn = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'DELETE',
    url: `/tablecolumn/${data.columnId}`
  })

  callback(res.data, false)
})

/**
 * Update task (in table)
 * @param {String} data.taskId task id
 * @param {String} data.workspaceId Workspace Id
 * @param {Array} data.dynamicFields tableColumnId, value
 * @param {Function} callback callback function
 */
export const updateDynamicField = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: `/task/dynamic/${data.taskId}`,
      data: {
        workSpace: data.workspaceId,
        dynamicField: data.dynamicFields
      }
    })

    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Upload attachment from dynamic col (table)
 * @param {String} data.taskId task id
 * @param {String} data.workspaceId Workspace Id
 * @param {Array} data.dynamicFields tableColumnId, value
 * @param {Function} callback callback function
 */
export const uploadDynamicColAttachment = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'PUT',
      url: '/task/dynamic/attachment',
      data: {
        workSpace: data.workspaceId,
        task: data.taskId,
        dynamicField: data.dynamicFields
      }
    })

    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Delete attachment from dynamic col (table)
 * @param {String} id task id
 * @param {String} url img url
 * @param {String} id column id
 * @param {Function} callback callback function (optional)
 */
export const deleteDynamicColAttachment = (data, callback) => {
  return catchAsyncDispatch(async dispatch => {
    const res = await axios({
      method: 'DELETE',
      url: `/task/dynamic/attachment?task=${data.taskId}&url=${data.url}&taskColumnId=${data.columnId}`
    })

    if (callback) callback(res.data)
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
  }, callback)
}

// /**
//  * Fetch all task attachments by workspace id
//  * @param {String} id workspace Id
//  * @param {Function} callback callback function
//  */
// export const fetchAllTaskAttachmentsByWorkspace = async (
//   workspaceId,
//   callback
// ) => {
//   try {
//     const res = await axios({
//       url: `/fileassetsfile/alltask/${workspaceId}`,
//       method: 'GET'
//     })
//     if (callback) callback(res.data)
//   } catch (err) {
//     if (callback)
//       callback(
//         err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//         true
//       )
//   }
// }

/**
 *
 * @param {String} taskId task to copy
 * @param {Object} data task data to copy
 * @param {Function} callback
 * @returns
 */
export const duplicateTask = (data, callback) => {
  return catchAsyncDispatch(async (dispatch, getState) => {
    const { userWorkspaces } = getState()

    const res = await axios({
      method: 'POST',
      url: `task/duplicate/${data.taskId}`,
      data: data.data
    })

    const dueDateSectionId = getDueDateSectionId(res.data.dueDate)
    const assigneeSectionId = getAssigneeSectionId([
      ...res.data.assignedAgencyTeam,
      ...res.data.assignedClientTeam
    ])

    dispatch({
      type: TASKS.ADD_ONE_TASK,
      payload: { task: res.data, assigneeSectionId, dueDateSectionId }
    })

    const currentWorkspace = userWorkspaces.data.find(
      item => item._id === res.data.workSpace
    )

    dispatch({
      type: WORKSPACES.UPDATE_ONE_WORKSPACE,
      payload: {
        ...currentWorkspace,
        totalTask: (currentWorkspace.totalTask ?? 0) + 1
      }
    })

    if (callback) callback(res.data)
  }, callback)
}

/**
 * fetch all deleted tasks
 * @param {Function} callback callback function
 */
export const fetchDeletedTasks = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'GET',
    url: '/task/trash'
  })

  // Taking only the required data
  const transformedData = res.data.map(item => ({
    _id: item._id,
    name: item.title,
    deletedOn: item.deletedOn,
    deletedBy: item.deletedBy,
    taskSection: item.taskSection,
    workSpace: item.workSpace,
    workspaceBoard: item.workspaceBoard
  }))

  if (callback) callback(transformedData)
})

/**
 * restore task from trash
 * @param {Object} data.task
 * @param {Function} callback callback function
 */
export const restoreDeletedTask = catchAsync(async (data, callback) => {
  const res = await axios({
    method: 'PUT',
    url: `/task/${data.task._id}`,
    data: {
      isDeleted: false,
      deletedOn: null,
      deletedBy: null,
      taskSection: data.task.taskSection,
      workSpace: data.task.workSpace
    }
  })

  if (callback) callback(res.data)
})

export const resetOverdueAndUnscheduledTasks = () => {
  return {
    type: TASKS.RESET_UNSCHEDULED_AND_OVERDUE_TASKS
  }
}

export const moveSectionTask = ({
  sourceIndex,
  destinationIndex,
  sourceId,
  destinationId,
  taskId,
  groupBy
}) => {
  return {
    type: TASKS.MOVE_SECTION_TASK,
    payload: {
      sourceIndex,
      destinationIndex,
      sourceId,
      destinationId,
      taskId,
      groupBy
    }
  }
}

export const resetAllTasksData = () => {
  return { type: TASKS.RESET_ALL_DATA }
}

export const resetSectionTasksData = () => {
  return { type: TASKS.RESET_SECTION_TASKS_DATA }
}
