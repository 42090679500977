import {
  getAssigneeSectionId,
  getDueDateSectionId
} from 'components/Boards/BoardView/boardSectionsHelper'
// import OverdueTasks from 'components/Workspace/ProjectStats/OverdueTasks'

export const TASKS = {
  LOADING: 'TASKS_LOADING',
  FETCHED: 'ALL_TASKS_FETCHED',
  FETCHED_PRIORITY_TASKS: 'FETCHED_PRIORITY_TASKS',
  LOAD_MORE_PRIORITY_TASKS: 'TASKS_LOAD_MORE_PRIORITY_TASKS',
  ADD_ONE_TASK: 'ADD_ONE_TASK',
  DELETE_ONE_TASK: 'DELETE_ONE_TASK',
  ERROR: 'ALL_TASKS_ERROR',
  UPDATE_ONE_TASK: 'UPDATE_ONE_TASK',
  UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
  FETCH_LOGS: 'FETCH_TASK_LOGS',
  // REORDER_TASKS: 'REORDER_TASKS',
  UPDATE_SECTION_TASKS: 'TASKS_UPDATE_SECTION_TASKS',
  UPDATE_POSITION: 'TASKS_UPDATE_POSITION',
  UPDATE_TASK_SECTION: 'TASKS_UPDATE_TASK_SECTION',
  MARK_AS_COMPLETE: 'TASKS_MARK_AS_COMPLETE',
  FETCHED_SECTION_TASKS: 'TASKS_FETCHED_SECTION_TASKS',
  FETCHED_LIST_VIEW_TASKS: 'TASKS_FETCHED_LIST_VIEW_TASKS',
  FETCHED_CALENDAR_VIEW_TASKS: 'TASKS_FETCHED_CALENDAR_VIEW_TASKS',
  LOAD_MORE_SECTION_TASKS: 'TASKS_LOAD_MORE_SECTION_TASKS',
  LOAD_MORE_LIST_VIEW_TASKS: 'TASKS_LOAD_MORE_LIST_VIEW_TASKS',
  MOVE_SECTION_TASK: 'TASKS_MOVE_SECTION_TASK',
  MOVE_DUE_DATE_TASK: 'TASKS_MOVE_DUE_DATE_TASK',
  ADD_TASK_IN_SECTION: 'TASKS_ADD_TASK_IN_SECTION',
  ADD_TASK_IN_LIST: 'TASKS_ADD_TASK_IN_LIST',
  ADD_TASK_IN_CALENDAR: 'TASKS_ADD_TASK_IN_CALENDAR',
  REMOVE_TASK_FROM_SECTION: 'TASKS_REMOVE_TASK_FROM_SECTION',
  REMOVE_TASK_FROM_LIST_VIEW: 'TASKS_REMOVE_TASK_FROM_LIST_VIEW',
  REMOVE_TASK_FROM_CALENDAR_VIEW: 'TASKS_REMOVE_TASK_FROM_CALENDAR_VIEW',
  DELETE_TASK_SECTION: 'TASKS_DELETE_SECTION',
  FETCHED_PUBLIC_TASKS: 'TASKS_FETCHED_PUBLIC_TASKS',
  ADD_ONE_SUBTASK_COUNT: 'TASKS_ADD_ONE_SUBTASK_COUNT',
  REMOVE_ONE_SUBTASK_COUNT: 'TASKS_REMOVE_ONE_SUBTASK_COUNT',
  ADD_ONE_COMPLETED_SUBTASK: 'TASKS_ADD_ONE_COMPLETED_SUBTASK',
  REMOVE_ONE_COMPLETED_SUBTASK: 'TASKS_REMOVE_ONE_COMPLETED_SUBTASK',
  FETCHED_UNSCHEDULED_TASKS: 'FETCHED_UNSCHEDULED_TASKS',
  FETCHED_OVERDUE_TASKS: 'FETCHED_OVERDUE_TASKS',
  FETCHED_MORE_UNSCHEDULED_TASKS: 'FETCHED_MORE_UNSCHEDULED_TASKS',
  FETCHED_MORE_OVERDUE_TASKS: 'FETCHED_MORE_OVERDUE_TASKS',
  RESET_UNSCHEDULED_AND_OVERDUE_TASKS: 'RESET_UNSCHEDULED_AND_OVERDUE_TASKS',
  RESET_ALL_DATA: 'TASKS_RESET_ALL_DATA',
  RESET_SECTION_TASKS_DATA: 'TASKS_RESET_SECTION_TASKS_DATA',
  ADD_ONE_PRIORITY_TASK: 'TASKS_ADD_ONE_PRIORITY_TASK',
  REMOVE_ONE_PRIORITY_TASK: 'TASKS_REMOVE_ONE_PRIORITY_TASK'
}

const initialState = {
  error: false,
  loading: {
    all: true,
    priority: true
  },
  publicTasksData: [],
  data: {},
  priorityTasks: {
    loading: true,
    fetched: false,
    data: [],
    metadata: {}
  },
  logs: [],
  sectionWiseTasks: {},
  dueDateWiseTasks: {},
  assigneeWiseTasks: {},
  sequenceWiseTasks: {},
  listViewTasks: {
    loading: true,
    fetched: false,
    metadata: { total: 0, page: 0 },
    data: []
  },
  calendarViewTasks: {
    loading: true,
    fetched: false,
    metadata: {},
    data: []
  },
  unscheduledTasks: {
    loading: true,
    metadata: {},
    data: []
  },
  overdueTasks: {
    loading: true,
    metadata: {},
    data: []
  }
}
// const isPriorityTask = task => {
//   if (task.dueDate) {
//     return 10 > (new Date() - new Date(task.dueDate)) / (1000 * 3600 * 24)
//   } else {
//     return false
//   }
// }

// const sortPriorityTasksInOrder = tasks => {
//   return tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
// }

export const groupByKeyWords = {
  status: 'sectionWiseTasks',
  dueDate: 'dueDateWiseTasks',
  assignee: 'assigneeWiseTasks',
  sequence: 'sequenceWiseTasks'
}

export const groupByIndexKey = {
  status: 'orderIndex',
  dueDate: 'dueDateOrderIndex',
  assignee: 'assigneeOrderIndex'
}

export const tasksReducer = (state = initialState, action) => {
  const checkTaskExists = (section, taskId) => {
    if (!section) return false
    return section.data.includes(taskId)
  }

  const handleMoveTaskInSameSection = ({ section, sectionId, task }) => {
    return {
      ...section,
      data: section.data
        .filter(taskId => taskId !== task._id)
        .map(taskId => state.data[taskId])
        .concat(task)
        .sort(
          (arg1, arg2) =>
            parseFloat(arg1.orderIndex) - parseFloat(arg2.orderIndex)
        )
        .map(task => task._id)
    }
  }

  const handleMoveTaskBetweenSections = ({
    sourceSection,
    destinationSection,
    task
  }) => {
    // For some reason if the task does not exist in the source section, then return
    return {
      updatedSourceSection: {
        ...sourceSection,
        metadata: {
          ...sourceSection.metadata,
          total: sourceSection.metadata.total - 1
        },
        data: sourceSection.data.filter(taskId => taskId !== task._id)
      },
      updatedDestinationSection: {
        ...destinationSection,
        metadata: {
          ...destinationSection?.metadata,
          total: (destinationSection?.metadata?.total ?? 0) + 1
        },
        data: destinationSection
          ? destinationSection.data
              .map(taskId => state.data[taskId])
              .concat(task)
              .sort(
                (arg1, arg2) =>
                  parseFloat(arg1.orderIndex) - parseFloat(arg2.orderIndex)
              )
              .map(task => task._id)
          : [task._id]
      }
    }

    // updatedSourceSection = {
    //   ...updatedState.sectionWiseTasks,
    //   [prevSectionId]: {
    //     ...updatedState.sectionWiseTasks[prevSectionId],
    //     metadata: {
    //       ...updatedState.sectionWiseTasks[prevSectionId].metadata,
    //       total: updatedState.sectionWiseTasks[prevSectionId].metadata.total - 1
    //     },
    //     data: updatedState.sectionWiseTasks[prevSectionId].data.filter(
    //       taskId => taskId !== task._id
    //     )
    //   },
    //   [nextSectionId]: {

    //   }
    // }
  }

  switch (action.type) {
    case TASKS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
        error: false
      }

    case TASKS.FETCHED_UNSCHEDULED_TASKS:
      const unscheduledTaskObj = {}
      action.payload.data.forEach(e => {
        unscheduledTaskObj[e._id] = e
      })
      return {
        ...state,
        data: { ...state.data, ...unscheduledTaskObj },
        unscheduledTasks: {
          ...state.unscheduledTasks,
          metadata: action.payload.metadata,
          data: action.payload.data.map(e => e._id)
        }
      }

    case TASKS.FETCHED_OVERDUE_TASKS:
      const overdueTaskObj = {}
      action.payload.data.forEach(e => {
        overdueTaskObj[e._id] = e
      })
      return {
        ...state,
        data: { ...state.data, ...overdueTaskObj },
        overdueTasks: {
          ...state.overdueTasks,
          metadata: action.payload.metadata,
          data: action.payload.data.map(e => e._id)
        }
      }

    // appending unscheduled tasks on click of Load More button
    case TASKS.FETCHED_MORE_UNSCHEDULED_TASKS:
      const moreUnscheduledTaskObj = {}
      action.payload.data.forEach(e => {
        moreUnscheduledTaskObj[e._id] = e
      })

      return {
        ...state,
        data: { ...state.data, ...moreUnscheduledTaskObj },
        unscheduledTasks: {
          ...state.unscheduledTasks,
          data: [
            ...state.unscheduledTasks.data,
            ...action.payload.data.map(e => e._id)
          ]
        }
      }

    // appending overdue tasks on click of Load More button
    case TASKS.FETCHED_MORE_OVERDUE_TASKS:
      const moreOverdueTaskObj = {}
      action.payload.data.forEach(e => {
        moreOverdueTaskObj[e._id] = e
      })

      return {
        ...state,
        data: { ...state.data, ...moreOverdueTaskObj },
        overdueTasks: {
          ...state.overdueTasks,
          data: [
            ...state.overdueTasks.data,
            ...action.payload.data.map(e => e._id)
          ]
        }
      }

    case TASKS.FETCHED_PRIORITY_TASKS: {
      const updatedData = { ...state }
      updatedData.data = { ...updatedData.data }

      action.payload.data.forEach(task => {
        if (!updatedData.data[task._id]) updatedData.data[task._id] = task
      })

      updatedData.priorityTasks = {
        loading: false,
        fetched: true,
        data: action.payload.data.map(task => task._id),
        metadata: action.payload.metadata
      }

      return updatedData
    }

    case TASKS.LOAD_MORE_PRIORITY_TASKS: {
      const updatedData = { ...state }
      updatedData.data = { ...updatedData.data }
      const taskIds = action.payload.data.map(task => task._id)

      action.payload.data.forEach(task => {
        if (!updatedData.data[task._id]) updatedData.data[task._id] = task
      })

      updatedData.priorityTasks = {
        loading: false,
        fetched: true,
        data: [...updatedData.priorityTasks.data, ...taskIds],
        metadata: action.payload.metadata
      }

      return updatedData
    }

    case TASKS.ADD_ONE_TASK: {
      const {
        task,
        assigneeSectionId,
        dueDateSectionId,
        sequenceId
      } = action.payload
      const taskId = task._id,
        taskSectionId =
          typeof task.taskSection === 'object'
            ? task.taskSection._id
            : task.taskSection

      const updatedData = {
        ...state,
        data: {
          ...state.data,
          [task._id]: action.payload.task
        },
        calendarViewTasks: {
          ...state.calendarViewTasks,
          metadata: {
            ...state.calendarViewTasks.metadata,
            total: (state.calendarViewTasks.metadata.total ?? 0) + 1
          },
          data: [...state.calendarViewTasks.data, taskId]
        },
        listViewTasks: {
          ...state.listViewTasks,
          metadata: {
            ...state.listViewTasks.metadata,
            total: (state.listViewTasks.metadata.total ?? 0) + 1
          },
          data: [taskId, ...state.listViewTasks.data]
        }
      }

      //when creating task adding that task to overdue if the due date is behind of the current date
      if (
        !state.overdueTasks.data.includes(taskId) &&
        new Date(task.dueDate) - new Date() < 0
      ) {
        updatedData.overdueTasks = {
          ...state.overdueTasks,
          data: [...state.overdueTasks.data, taskId],
          metadata: {
            ...state.overdueTasks.metadata,
            total: state.overdueTasks.metadata.total + 1
          }
        }
      }

      if (updatedData.sectionWiseTasks[taskSectionId]) {
        const firstTaskId = updatedData.sectionWiseTasks[taskSectionId].data[0]
        let top = false

        if (
          firstTaskId &&
          task.orderIndex <
            parseFloat(updatedData.data[firstTaskId]['orderIndex'])
        ) {
          top = true
        }

        updatedData.sectionWiseTasks = {
          ...state.sectionWiseTasks,
          [taskSectionId]: {
            metadata: {
              ...state.sectionWiseTasks[taskSectionId].metadata,
              total: state.sectionWiseTasks[taskSectionId].metadata.total + 1
            },
            data: top
              ? [taskId, ...state.sectionWiseTasks[taskSectionId].data]
              : [...state.sectionWiseTasks[taskSectionId].data, taskId]
          }
        }
      }
      if (updatedData.dueDateWiseTasks[dueDateSectionId]) {
        const firstTaskId =
          updatedData.dueDateWiseTasks[dueDateSectionId].data[0]
        let top = false

        if (
          firstTaskId &&
          task.dueDateOrderIndex <
            parseFloat(updatedData.data[firstTaskId]['dueDateOrderIndex'])
        ) {
          top = true
        }

        updatedData.dueDateWiseTasks = {
          ...state.dueDateWiseTasks,
          [dueDateSectionId]: {
            metadata: {
              ...state.dueDateWiseTasks[dueDateSectionId].metadata,
              total: state.dueDateWiseTasks[dueDateSectionId].metadata.total + 1
            },
            data: top
              ? [taskId, ...state.dueDateWiseTasks[dueDateSectionId].data]
              : [...state.dueDateWiseTasks[dueDateSectionId].data, taskId]
          }
        }
      }
      if (updatedData.assigneeWiseTasks[assigneeSectionId]) {
        const firstTaskId =
          updatedData.assigneeWiseTasks[assigneeSectionId].data[0]
        let top = false

        if (
          firstTaskId &&
          task.assigneeOrderIndex <
            parseFloat(updatedData.data[firstTaskId]['assigneeSectionId'])
        ) {
          top = true
        }

        updatedData.assigneeWiseTasks = {
          ...state.assigneeWiseTasks,
          [assigneeSectionId]: {
            metadata: {
              ...state.assigneeWiseTasks[assigneeSectionId].metadata,
              total:
                state.assigneeWiseTasks[assigneeSectionId].metadata.total + 1
            },
            data: top
              ? [taskId, ...state.assigneeWiseTasks[assigneeSectionId].data]
              : [...state.assigneeWiseTasks[assigneeSectionId].data, taskId]
          }
        }
      }
      if (updatedData.sequenceWiseTasks[sequenceId]) {
        updatedData.sequenceWiseTasks = {
          ...state.sequenceWiseTasks,
          [sequenceId]: {
            metadata: {
              ...state.sequenceWiseTasks[sequenceId].metadata,
              total: state.sequenceWiseTasks[sequenceId].metadata.total + 1
            },
            data: [...state.sequenceWiseTasks[sequenceId].data, taskId]
          }
        }
      }
      return updatedData
    }

    case TASKS.UPDATE_ONE_TASK: {
      const task = action.payload
      const updatedState = { ...state }
      const oldTask = updatedState.data[task._id]

      if (!oldTask) return state

      // 1. Update task data in main store (data)
      updatedState.data = {
        ...updatedState.data,
        [action.payload._id]: {
          ...action.payload,
          totalSubtasks: oldTask.totalSubtasks,
          completedSubtasks: oldTask.completedSubtasks
        }
      }

      // 2. Move task from section if needed
      // Note: here we don't know what was updated in the task, so we are checking
      // and moving cards from one section to another if needed (will improve it soon)
      // i) check if task's section was updated
      if (
        oldTask.taskSection._id !== task.taskSection._id ||
        oldTask.orderIndex !== task.orderIndex
      ) {
        const prevSectionId = oldTask.taskSection._id,
          nextSectionId = task.taskSection._id

        // only if section exists
        if (updatedState.sectionWiseTasks[prevSectionId]) {
          // For some reason if the task does not exist in the source section, then return
          if (
            !checkTaskExists(
              updatedState.sectionWiseTasks[prevSectionId],
              task._id
            )
          ) {
            return state
          }

          if (prevSectionId === nextSectionId) {
            // If tasks were moved in the same section
            updatedState.sectionWiseTasks[
              prevSectionId
            ] = handleMoveTaskInSameSection({
              section: updatedState.sectionWiseTasks[prevSectionId],
              sectionId: prevSectionId,
              task
            })
          } else {
            // For some reason if the task already exists in destination section, then return
            if (
              checkTaskExists(
                updatedState.sectionWiseTasks[nextSectionId],
                task._id
              )
            ) {
              return state
            }

            const {
              updatedSourceSection,
              updatedDestinationSection
            } = handleMoveTaskBetweenSections({
              sourceSection: updatedState.sectionWiseTasks[prevSectionId],
              destinationSection: updatedState.sectionWiseTasks[nextSectionId],
              task
            })

            updatedState.sectionWiseTasks = {
              ...updatedState.sectionWiseTasks,
              [prevSectionId]: updatedSourceSection,
              [nextSectionId]: updatedDestinationSection
            }
          }
        }
      }

      // ii) check if task's due date was updated
      if (
        oldTask.dueDate !== task.dueDate ||
        oldTask.dueDateOrderIndex !== task.dueDateOrderIndex
      ) {
        //if someone removes the due date the task will be shifted to unscheduled
        if (task.dueDate == null) {
          //checking if that task is already there in unscheduled tasks or not
          if (!state.unscheduledTasks.data.includes(action.payload._id)) {
            updatedState.unscheduledTasks = {
              ...state.unscheduledTasks,
              data: [...state.unscheduledTasks.data, action.payload._id],
              metadata: {
                ...state.unscheduledTasks.metadata,
                total: state.unscheduledTasks.metadata.total + 1
              }
            }

            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: state.overdueTasks.data.filter(
                e => e != action.payload._id
              ),
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total - 1
              }
            }
          }
          //if that task includes in unschedule and still it has a overdue date then we are not removing that task from unschedule
          if (state.unscheduledTasks.data.includes(action.payload._id)) {
            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: state.overdueTasks.data.filter(
                e => e != action.payload._id
              ),
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total - 1
              }
            }
          }
        } else {
          //if the task is not in overdue and unschedule both and the due date is
          //before the current date then we are adding the task to overdue
          if (
            !state.overdueTasks.data.includes(action.payload._id) &&
            new Date(task.dueDate) - new Date() < 0 &&
            !state.unscheduledTasks.data.includes(action.payload._id)
          ) {
            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: [...state.overdueTasks.data, action.payload._id],
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total + 1
              }
            }
          }
          //if the task due date is ahead of the current date and it is included in
          //overdue then we are removing it from overdue
          else if (
            new Date(task.dueDate) - new Date() > 0 &&
            state.overdueTasks.data.includes(action.payload._id)
          ) {
            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: state.overdueTasks.data.filter(
                e => e != action.payload._id
              ),
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total - 1
              }
            }
          }
          //if the task due date is behind the current date and it is included in unscheduled
          //tasks then we are removing it from unschedule and adding it to overdue and there is a start date
          else if (
            new Date(task.dueDate) - new Date() < 0 &&
            state.unscheduledTasks.data.includes(action.payload._id) &&
            !state.overdueTasks.data.includes(action.payload._id) &&
            task.startDate
          ) {
            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: [...state.overdueTasks.data, action.payload._id],
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total + 1
              }
            }

            updatedState.unscheduledTasks = {
              ...state.unscheduledTasks,
              data: state.unscheduledTasks.data.filter(
                e => e != action.payload._id
              ),
              metadata: {
                ...state.unscheduledTasks.metadata,
                total: state.unscheduledTasks.metadata.total - 1
              }
            }
          }
          //if the task due date is behind the current date and it is included in unscheduled
          //and it doesn't have a start date then we are adding it to overdue task and not removing it from unscheduled tasks
          else if (
            new Date(task.dueDate) - new Date() < 0 &&
            state.unscheduledTasks.data.includes(action.payload._id) &&
            !state.overdueTasks.data.includes(action.payload._id) &&
            task.startDate == null
          ) {
            updatedState.overdueTasks = {
              ...state.overdueTasks,
              data: [...state.overdueTasks.data, action.payload._id],
              metadata: {
                ...state.overdueTasks.metadata,
                total: state.overdueTasks.metadata.total + 1
              }
            }
          }
          //if the task due date is ahead the current date and it is included in unscheduled
          //tasks then we are removing it from unschedule
          else if (
            new Date(task.dueDate) - new Date() > 0 &&
            state.unscheduledTasks.data.includes(action.payload._id)
          ) {
            updatedState.unscheduledTasks = {
              ...state.unscheduledTasks,
              data: state.unscheduledTasks.data.filter(
                e => e != action.payload._id
              ),
              metadata: {
                ...state.unscheduledTasks.metadata,
                total: state.unscheduledTasks.metadata.total - 1
              }
            }
          }
        }
        const prevSectionId = getDueDateSectionId(oldTask.dueDate),
          nextSectionId = getDueDateSectionId(task.dueDate)

        // only if section exists
        if (updatedState.dueDateWiseTasks[prevSectionId]) {
          // For some reason if the task already exists in destination section, then return
          if (
            !checkTaskExists(
              updatedState.dueDateWiseTasks[prevSectionId],
              task._id
            )
          ) {
            return state
          }

          // const prevSectionTasks =
          //   updatedState.dueDateWiseTasks[prevSectionId].data

          if (prevSectionId === nextSectionId) {
            updatedState.dueDateWiseTasks[
              prevSectionId
            ] = updatedState.sectionWiseTasks[
              prevSectionId
            ] = handleMoveTaskInSameSection({
              section: updatedState.dueDateWiseTasks[prevSectionId],
              sectionId: prevSectionId,
              task
            })
            // updatedState.dueDateWiseTasks[prevSectionId] = {
            //   ...updatedState.dueDateWiseTasks[prevSectionId],
            //   data: updatedState.dueDateWiseTasks[prevSectionId]
            //     .filter(taskId => taskId !== task._id)
            //     .map(taskId => updatedState.data[taskId])
            //     .concat(task)
            //     .sort(
            //       (arg1, arg2) =>
            //         parseFloat(arg1.dueDateOrderIndex) -
            //         parseFloat(arg2.dueDateOrderIndex)
            //     )
            //     .map(task => task._id)
            // }
          } else {
            // b) Adding task to new section
            if (
              checkTaskExists(
                updatedState.dueDateWiseTasks[nextSectionId],
                task._id
              )
            ) {
              return state
            }

            const {
              updatedSourceSection,
              updatedDestinationSection
            } = handleMoveTaskBetweenSections({
              sourceSection: updatedState.dueDateWiseTasks[prevSectionId],
              destinationSection: updatedState.dueDateWiseTasks[nextSectionId],
              task
            })

            updatedState.dueDateWiseTasks = {
              ...updatedState.dueDateWiseTasks,
              [prevSectionId]: updatedSourceSection,
              [nextSectionId]: updatedDestinationSection
            }
          }
        }
      }

      // iii) check if task's assignees were updated
      if (
        oldTask.assignedAgencyTeam.length !== task.assignedAgencyTeam.length ||
        oldTask.assignedClientTeam.length !== task.assignedClientTeam.length ||
        oldTask.assigneeOrderIndex !== task.assigneeOrderIndex
      ) {
        const prevSectionId = getAssigneeSectionId([
            ...oldTask.assignedAgencyTeam,
            ...oldTask.assignedClientTeam
          ]),
          nextSectionId = getAssigneeSectionId([
            ...task.assignedAgencyTeam,
            ...task.assignedClientTeam
          ])

        // only if section exists
        if (updatedState.assigneeWiseTasks[prevSectionId]) {
          if (
            !checkTaskExists(
              updatedState.assigneeWiseTasks[prevSectionId],
              task._id
            )
          ) {
            return state
          }

          if (!updatedState.assigneeWiseTasks[nextSectionId]) {
            updatedState.assigneeWiseTasks = {
              ...updatedState.assigneeWiseTasks,
              [prevSectionId]: {
                metadata: {
                  ...updatedState.assigneeWiseTasks[prevSectionId].metadata,
                  total:
                    updatedState.assigneeWiseTasks[prevSectionId].metadata
                      .total - 1
                },
                data: updatedState.assigneeWiseTasks[prevSectionId].data.filter(
                  taskId => taskId !== task._id
                )
              },
              [nextSectionId]: {
                metadata: { page: 0, total: 1 },
                data: [task._id]
              }
            }
          } else {
            if (prevSectionId === nextSectionId) {
              updatedState.assigneeWiseTasks = handleMoveTaskInSameSection({
                section: updatedState.assigneeWiseTasks[prevSectionId],
                sectionId: prevSectionId,
                task
              })
              // updatedState.assigneeWiseTasks = {
              //   ...updatedState.assigneeWiseTasks,
              //   [prevSectionId]: {
              //     ...updatedState.assigneeWiseTasks[prevSectionId],
              //     data: updatedState.assigneeWiseTasks[prevSectionId].data
              //       .filter(taskId => taskId !== task._id)
              //       .map(taskId => updatedState.data[taskId])
              //       .concat(task)
              //       .sort(
              //         (arg1, arg2) =>
              //           parseFloat(arg1.dueDateOrderIndex) -
              //           parseFloat(arg2.dueDateOrderIndex)
              //       )
              //       .map(task => task._id)
              //   }
              // }
            } else {
              // b) Adding task to new section
              if (
                checkTaskExists(
                  updatedState.assigneeWiseTasks[nextSectionId],
                  task._id
                )
              ) {
                return state
              }

              const {
                updatedSourceSection,
                updatedDestinationSection
              } = handleMoveTaskBetweenSections({
                sourceSection: updatedState.assigneeWiseTasks[prevSectionId],
                destinationSection:
                  updatedState.assigneeWiseTasks[nextSectionId],
                task
              })

              updatedState.assigneeWiseTasks = {
                ...updatedState.assigneeWiseTasks,
                [prevSectionId]: updatedSourceSection,
                [nextSectionId]: updatedDestinationSection
              }
            }
          }
        }
      }
      return updatedState
    }

    case TASKS.UPLOAD_ATTACHMENT: {
      const updatedData = { ...state.data }

      if (updatedData[action.payload._id]) {
        updatedData[action.payload._id].imageUrl = action.payload.imageUrl
      }

      return {
        ...state,
        data: updatedData
      }
    }

    case TASKS.FETCH_LOGS:
      return {
        ...state,
        logs: action.payload
      }

    case TASKS.DELETE_ONE_TASK: {
      const updatedData = {
        ...state
      }
      const { task, dueDateSectionId, assigneeSectionId } = action.payload

      const taskId = task._id,
        sectionId =
          typeof task.taskSection === 'object'
            ? task.taskSection._id
            : task.taskSection

      if (updatedData.data[taskId]) {
        delete updatedData.data[taskId]
      } else {
        updatedData.priorityTasks = {
          ...updatedData.priorityTasks,
          data: state.priorityTasks.data.filter(i => i._id !== taskId)
        }
      }

      if (updatedData.priorityTasks.data.includes(taskId)) {
        updatedData.priorityTasks = {
          ...updatedData.priorityTasks,
          metadata: {
            ...updatedData.priorityTasks.metadata,
            total: updatedData.priorityTasks.metadata.total - 1
          },
          data: updatedData.priorityTasks.data.filter(id => id !== taskId)
        }
      }

      if (updatedData.sectionWiseTasks[sectionId]) {
        updatedData.sectionWiseTasks = {
          ...updatedData.sectionWiseTasks,
          [sectionId]: {
            metadata: {
              ...updatedData.sectionWiseTasks[sectionId].metadata,
              total: updatedData.sectionWiseTasks[sectionId].metadata.total - 1
            },
            data: updatedData.sectionWiseTasks[sectionId].data.filter(
              id => id !== taskId
            )
          }
        }
      }
      if (updatedData.dueDateWiseTasks[dueDateSectionId]) {
        updatedData.dueDateWiseTasks = {
          ...updatedData.dueDateWiseTasks,
          [dueDateSectionId]: {
            metadata: {
              ...updatedData.dueDateWiseTasks[dueDateSectionId].metadata,
              total:
                updatedData.dueDateWiseTasks[dueDateSectionId].metadata.total -
                1
            },
            data: updatedData.dueDateWiseTasks[dueDateSectionId].data.filter(
              id => id !== taskId
            )
          }
        }
      }
      if (updatedData.assigneeWiseTasks[assigneeSectionId]) {
        updatedData.assigneeWiseTasks = {
          ...updatedData.assigneeWiseTasks,
          [assigneeSectionId]: {
            metadata: {
              ...updatedData.assigneeWiseTasks[assigneeSectionId].metadata,
              total:
                updatedData.assigneeWiseTasks[assigneeSectionId].metadata
                  .total - 1
            },
            data: updatedData.assigneeWiseTasks[assigneeSectionId].data.filter(
              id => id !== taskId
            )
          }
        }
      }

      if (updatedData.listViewTasks.data.includes(taskId)) {
        updatedData.listViewTasks = {
          ...updatedData.listViewTasks,
          metadata: {
            ...updatedData.listViewTasks.metadata,
            total: updatedData.listViewTasks.metadata.total - 1
          },
          data: updatedData.listViewTasks.data.filter(id => id !== taskId)
        }
      }

      if (updatedData.calendarViewTasks.data.includes(taskId)) {
        updatedData.calendarViewTasks = {
          ...updatedData.calendarViewTasks,
          metadata: {
            ...updatedData.calendarViewTasks.metadata,
            total: updatedData.calendarViewTasks.metadata.total - 1
          },
          data: updatedData.calendarViewTasks.data.filter(id => id !== taskId)
        }
      }

      // if (state.overdueTasks.data.includes(taskId)) {
      //   updatedData.overdueTasks = {
      //     ...state.overdueTasks,
      //     data: [...state.overdueTasks.data, taskId],
      //     metadata: {
      //       ...state.overdueTasks.metadata,
      //       total: state.overdueTasks.metadata.total - 1
      //     }
      //   }
      // }
      return updatedData
      // return {
      //   ...state,
      //   // loading: false,
      //   dataNew: filteredTasks,
      //   priorityTasks: {
      //     ...state.priorityTasks,
      //     data: state.priorityTasks.data.filter(i => i._id !== action.payload)
      //   }
      // }
    }

    // case TASKS.REORDER_TASKS:
    //   return {
    //     ...state
    //     // data: action.payload
    //   }

    case TASKS.ERROR:
      return {
        ...state,
        loading: {
          all: false,
          priority: false
        },
        error: action.payload
      }

    case TASKS.UPDATE_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload._id]: {
            ...action.payload,
            totalSubtasks: state.data[action.payload._id].totalSubtasks,
            completedSubtasks: state.data[action.payload._id].completedSubtasks
          }
        }
      }

    case TASKS.MARK_AS_COMPLETE: {
      const updatedData = { ...state }
      if (updatedData.data[action.payload.taskId]) {
        updatedData.data = {
          ...updatedData.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            markAsComplete: action.payload.isComplete
          }
        }
      }

      return updatedData
      // else {
      //   return {
      //     ...state,
      //     priorityTasks: {
      //       ...state.priorityTasks,
      //       data: state.priorityTasks.data.map(item => {
      //         if (item._id === action.payload.taskId) {
      //           return { ...item, markAsComplete: action.payload.isComplete }
      //         }
      //         return item
      //       })
      //     }
      //   }
      // }
    }

    case TASKS.UPDATE_TASK_SECTION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...action.payload,
            totalSubtasks: state.data[action.payload._id].totalSubtasks,
            completedSubtasks: state.data[action.payload._id].completedSubtasks
          }
        }
        // priorityTasks: {
        //   ...state.priorityTasks,
        //   data: state.priorityTasks.data.map(item => {
        //     if (item._id !== action.payload.taskId) return item
        //     return { ...item, taskSection: action.payload.section }
        //   })
        // }
      }

    case TASKS.FETCHED_SECTION_TASKS: {
      const { sectionId, groupBy, tasks } = action.payload
      const updatedData = { ...state }
      const key = groupByKeyWords[groupBy]

      const updatedDataNew = { ...updatedData.data }

      tasks.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew

      updatedData[key] = {
        ...updatedData[key],
        [sectionId]: {
          metadata: tasks.metadata,
          data: tasks.data.map(task => task._id)
        }
      }

      return updatedData
    }

    case TASKS.LOAD_MORE_SECTION_TASKS: {
      const { sectionId, groupBy, tasks } = action.payload
      const updatedData = { ...state }
      const key = groupByKeyWords[groupBy]
      const taskIds = action.payload.tasks.data.map(task => task._id)

      const updatedDataNew = { ...updatedData.data }

      tasks.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew

      updatedData[key] = {
        ...updatedData[key],
        [sectionId]: {
          metadata: tasks.metadata,
          data: [...updatedData[key][sectionId].data, ...taskIds]
        }
      }

      return updatedData
    }

    case TASKS.FETCHED_LIST_VIEW_TASKS: {
      const dataTasks2 = { ...state.data }

      action.payload.data.forEach(task => {
        if (!dataTasks2[task._id]) dataTasks2[task._id] = task
      })

      return {
        ...state,
        data: dataTasks2,
        listViewTasks: {
          loading: false,
          fetched: true,
          metadata: action.payload.metadata,
          data: action.payload.data.map(task => task._id)
        }
      }
    }

    case TASKS.LOAD_MORE_LIST_VIEW_TASKS: {
      const updatedData = { ...state }
      const updatedDataNew = { ...updatedData.data }
      const taskIds = action.payload.data.map(task => task._id)

      action.payload.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew
      updatedData.listViewTasks = {
        ...updatedData.listViewTasks,
        metadata: action.payload.metadata,
        data: [...updatedData.listViewTasks.data, ...taskIds]
      }

      return updatedData
    }

    case TASKS.FETCHED_CALENDAR_VIEW_TASKS: {
      const tasksData = { ...state.data }

      action.payload.data.forEach(task => {
        if (!tasksData[task._id]) tasksData[task._id] = task
      })

      return {
        ...state,
        data: tasksData,
        calendarViewTasks: {
          loading: false,
          fetched: true,
          metadata: action.payload.metadata,
          data: action.payload.data.map(task => task._id)
        }
      }
    }

    case TASKS.MOVE_SECTION_TASK: {
      let {
        sourceIndex,
        destinationIndex,
        sourceId,
        destinationId,
        taskId,
        groupBy
      } = action.payload
      const key = groupByKeyWords[groupBy]
      sourceIndex =
        sourceIndex === undefined
          ? state[key][sourceId].data.findIndex(id => id === taskId)
          : sourceIndex

      if (sourceIndex === -1) return

      // if section exists (in most cases it does)
      if (state[key][destinationId]) {
        const destinationData = [...state[key][destinationId].data]

        // 1. Moving in the same section
        if (sourceId === destinationId) {
          destinationData.splice(sourceIndex, 1)
          destinationData.splice(
            destinationIndex ?? destinationData.length,
            0,
            taskId
          )
          return {
            ...state,
            [key]: {
              ...state[key],
              [destinationId]: {
                ...state[key][sourceId],
                data: destinationData
              }
            }
          }
        } else {
          // 2. Moving to another sections
          const sourceData = state[key][sourceId].data.filter(
            item => item !== taskId
          )
          destinationData.splice(
            destinationIndex ?? destinationData.length,
            0,
            taskId
          )

          return {
            ...state,
            [key]: {
              ...state[key],
              [sourceId]: {
                metadata: {
                  ...state[key][sourceId].metadata,
                  total: state[key][sourceId].metadata.total - 1
                },
                data: sourceData
              },
              [destinationId]: {
                metadata: {
                  ...state[key][destinationId].metadata,
                  total: state[key][destinationId].metadata.total + 1
                },
                data: destinationData
              }
            }
          }
        }
      } else {
        // If section not found creating new one (in group by assignee case)
        const sourceData = state[key][sourceId].data.filter(
          item => item !== taskId
        )

        return {
          ...state,
          [key]: {
            ...state[key],
            [sourceId]: {
              metadata: {
                ...state[key][sourceId].metadata,
                total: state[key][sourceId].metadata.total - 1
              },
              data: sourceData
            },
            [destinationId]: {
              metadata: { total: 1, page: 0 },
              data: [taskId]
            }
          }
        }
      }
    }

    case TASKS.ADD_TASK_IN_SECTION: {
      const { sectionId, task, groupBy } = action.payload
      const key = groupByKeyWords[groupBy]
      const updatedStateData = { ...state }

      if (updatedStateData[key][sectionId]) {
        const firstTaskId = updatedStateData[key][sectionId].data[0]

        if (firstTaskId) {
          updatedStateData[key] = {
            ...updatedStateData[key],
            [sectionId]: {
              metadata: {
                page: 0,
                total: 1
              },
              data: [task._id]
            }
          }
        } else {
          let firstTaskOrder =
              updatedStateData.data[firstTaskId][groupByIndexKey[groupBy]],
            newTaskOrder = task[groupByIndexKey[groupBy]]

          updatedStateData[key] = {
            ...updatedStateData[key],
            [sectionId]: {
              metadata: {
                page: updatedStateData[key][sectionId].metadata.page,
                total: updatedStateData[key][sectionId].metadata.total + 1
              },
              data:
                parseFloat(firstTaskOrder) < parseFloat(newTaskOrder)
                  ? [...updatedStateData[key][sectionId].data, task._id]
                  : [task._id, ...updatedStateData[key][sectionId].data]
            }
          }
        }
      }

      return updatedStateData
    }

    case TASKS.DELETE_TASK_SECTION: {
      const updatedStatusSections = { ...state.sectionWiseTasks }
      delete updatedStatusSections[action.payload.sectionId]

      return { ...state, sectionWiseTasks: updatedStatusSections }
    }

    case TASKS.FETCHED_PUBLIC_TASKS: {
      return {
        ...state,
        publicTasksData: action.payload,
        loading: { ...state.loading, all: false }
      }
    }

    case TASKS.UPDATE_SECTION_TASKS: {
      return {}
    }

    case TASKS.ADD_ONE_SUBTASK_COUNT: {
      let totalSubtasksCount =
        state.data[action.payload.taskId]?.totalSubtasks || 0

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            totalSubtasks: totalSubtasksCount + 1
          }
        }
      }
    }

    case TASKS.REMOVE_ONE_SUBTASK_COUNT: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            totalSubtasks: state.data[action.payload.taskId].totalSubtasks - 1
          }
        }
      }
    }

    case TASKS.ADD_ONE_COMPLETED_SUBTASK: {
      let completedSubtasks =
        state.data[action.payload.taskId]?.completedSubtasks || 0

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            completedSubtasks: completedSubtasks + 1
          }
        }
      }
    }

    case TASKS.REMOVE_ONE_COMPLETED_SUBTASK: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            completedSubtasks:
              state.data[action.payload.taskId].completedSubtasks - 1
          }
        }
      }
    }

    case TASKS.RESET_UNSCHEDULED_AND_OVERDUE_TASKS: {
      return {
        ...state,
        unscheduledTasks: {
          loading: true,
          metadata: {},
          data: []
        },
        overdueTasks: {
          loading: true,
          metadata: {},
          data: []
        }
      }
    }

    case TASKS.ADD_ONE_PRIORITY_TASK: {
      const idx = state.priorityTasks.data.findIndex(
        taskId => action.payload._id === taskId
      )

      if (idx !== -1) return state

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload._id]: action.payload
        },
        priorityTasks: {
          ...state.priorityTasks,
          data: [...state.priorityTasks.data, action.payload._id],
          metadata: {
            ...state.priorityTasks.metadata,
            total: state.priorityTasks.metadata.total + 1
          }
        }
      }
    }

    case TASKS.REMOVE_ONE_PRIORITY_TASK: {
      const idx = state.priorityTasks.data.findIndex(
        taskId => action.payload._id === taskId
      )

      if (idx === -1) return state

      return {
        ...state,
        priorityTasks: {
          ...state.priorityTasks,
          data: state.priorityTasks.data.filter(
            taskId => taskId !== action.payload._id
          ),
          metadata: {
            ...state.priorityTasks.metadata,
            total: state.priorityTasks.metadata.total - 1
          }
        }
      }
    }

    case TASKS.RESET_SECTION_TASKS_DATA: {
      return {
        ...state,
        sectionWiseTasks: {}
      }
    }

    case TASKS.RESET_ALL_DATA: {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
export default tasksReducer
