import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmailEditor from './EmailEditor'
import { Button, TextField } from '@material-ui/core'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import {
  createCampaignEmailTemplate,
  deleteEmailTemplateById,
  fetchCampaignEmailTemplates,
  updateCampaignEmailTemplate
} from 'thunks/emailTemplates/actions'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { ReactComponent as EnvelopeIcon } from 'static/svg/envelope.svg'
import { ReactComponent as ClockIcon } from 'static/svg/clock.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import clsx from 'clsx'
import { updateCampaignConfig } from 'thunks/Campaign/actions'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const ClientSignupEmailTemplate = ({
  campaignData,
  handleReplaceCampaignData
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { campaignId } = useParams()
  const inputRef = useRef()
  const emailEditorRef = useRef()
  const [emailTemplates, setEmailTemplates] = useState({
    loading: true,
    data: [
      {
        html: '',
        subject: ''
      }
    ]
  })
  const [stepsData, setStepsData] = useState({
    steps: []
  })
  const [loading, setLoading] = useState(false)
  const [activeTemplateId, setActiveTemplateId] = useState(null)
  const currentEmailTemplate = emailTemplates.data.find(
    item => item._id === activeTemplateId
  )
  const [createStepLoading, setCreateStepLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    fetchCampaignEmailTemplates({ campaignId }, (res, err) => {
      if (!err) {
        setEmailTemplates({ loading: false, data: res })
        setActiveTemplateId(res[0]._id)
      }
    })
  }, [])

  useEffect(() => {
    if (campaignData.config.sequences?.steps) {
      setStepsData({ steps: campaignData.config.sequences.steps })
    }
  }, [campaignData.config.sequences])

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = currentEmailTemplate?.subject
  }, [currentEmailTemplate])

  const handleSaveChanges = () => {
    const modifiedEmailBody = modifyEmailBody({
      campaignId,
      templateId: currentEmailTemplate._id,
      emailBody: emailEditorRef.current.getEmailBody()
    })

    const callback = (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        setLoading(false)
      } else {
        updateCampaignConfig(
          {
            campaignId,
            body: {
              ...campaignData,
              config: {
                ...campaignData.config,
                sequences: {
                  steps: stepsData.steps
                }
              }
            }
          },
          (res, err) => {
            setLoading(false)
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster(t('CHANGES_SAVED')))
            }
          }
        )
      }
    }

    if (currentEmailTemplate._id) {
      setLoading(true)
      updateCampaignEmailTemplate(
        {
          templateId: currentEmailTemplate._id,
          body: {
            html: modifiedEmailBody,
            subject: inputRef.current.value,
            campaign: campaignId
          }
        },
        callback
      )
    }
  }

  const updateSequenceDuration = (index, duration) => {
    setStepsData(prev => ({
      steps: prev.steps.map((item, idx) =>
        index === idx
          ? { ...item, delay: { ...item.delay, duration: parseInt(duration) } }
          : item
      )
    }))
  }

  const handleCreateNewStep = () => {
    setCreateStepLoading(true)
    createCampaignEmailTemplate(
      {
        body: {
          html: null,
          subject: null,
          campaign: campaignId
        }
      },
      (res, err) => {
        setCreateStepLoading(false)

        if (err) dispatch(fireErrorToaster(res))
        else {
          handleReplaceCampaignData(res.campaign)
          setEmailTemplates(prev => ({
            loading: false,
            data: [...prev.data, res.template]
          }))
          dispatch(fireSuccessToaster(t('SUCCESS')))
        }
      }
    )
  }

  const handleDeleteStep = templateId => {
    if (stepsData.steps.length === 1) {
      dispatch(fireErrorToaster(t('DEFAULT_EMAIL_TEMPLATE_DELETE_WARNING')))
      return
    }

    deleteEmailTemplateById({ templateId }, (res, err) => {
      if (err) dispatch(fireErrorToaster(res))
      else {
        handleReplaceCampaignData(res.campaign)
        setEmailTemplates(prev => ({
          loading: false,
          data: prev.data.filter(item => item._id !== templateId)
        }))
        dispatch(fireSuccessToaster(t('SUCCESS')))
      }
    })
  }

  if (emailTemplates.loading) return <LoadingAnimation />
  if (stepsData.steps.length === 0)
    return (
      <div className="bg-white border rounded-lg p-8 flex flex-col justify-center items-center">
        <div className="text-center text-gray-600 mb-4">
          {t('NO_EMAIL_TEMPLATE_MESSAGE')}
        </div>

        <LoadingButton
          size="large"
          loading={createStepLoading}
          onClick={handleCreateNewStep}
        >
          {t('ADD')} {t('TEMPLATE')}
        </LoadingButton>
      </div>
    )

  return (
    <>
      <div className="flex">
        <div className="w-80 mr-6">
          {stepsData.steps.map((item, index) => (
            <StepCard
              t={t}
              stepOrder={index + 1}
              isActive={currentEmailTemplate?._id === item.template}
              stepData={item}
              template={emailTemplates.data.find(
                temp => temp._id === item.template
              )}
              onClick={() => setActiveTemplateId(item.template)}
              onDurationChange={e =>
                updateSequenceDuration(index, e.target.value)
              }
              onDeleteStep={() => handleDeleteStep(item.template)}
            />
          ))}

          <div className="mt-10">
            <LoadingButton
              loading={createStepLoading}
              onClick={handleCreateNewStep}
              size="large"
              fullWidth
            >
              {t('CREATE')} {t('STEP')}
            </LoadingButton>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-white border rounded-xl">
            <div className="flex items-center py-4 px-4 justify-between">
              <div className="flex flex-1 items-center">
                <span className="text-sm text-gray-400 dark:bg-dark-main1 dark:text-dark-light2">
                  {t('SUBJECT')}:
                </span>
                <input
                  ref={inputRef}
                  placeholder="Enter subject"
                  className="ml-2 w-full font-medium text-gray-700 bg-white text-sm dark:bg-dark-main3 dark:text-dark-light"
                />
              </div>
              <div className="flex items-center space-x-2 ml-2">
                <Button
                  size="large"
                  variant="outlined"
                  className="whitespace-nowrap"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  &#123;&#125; Insert Variable
                </Button>
                <LoadingButton
                  size="large"
                  onClick={handleSaveChanges}
                  loading={loading}
                >
                  {t('SAVE')}
                </LoadingButton>
              </div>
            </div>

            <EmailEditor
              ref={emailEditorRef}
              key={activeTemplateId}
              defaultHtml={currentEmailTemplate?.html}
              // onChange={handleEmailBodyChange}
              placeholder={t('TYPE')}
              height={500}
            />
          </div>
        </div>
      </div>
      <VariablesPopper
        t={t}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
    </>
  )
}

const VariablesPopper = ({ t, anchorEl, onClose }) => {
  const dispatch = useDispatch()

  const variables = [
    {
      heading: t('SENDER'),
      variables: [
        { label: 'Name', value: '{{sender.fromName}}' },
        { label: 'Reply Name', value: '{{sender.replyName}}' },
        { label: 'Email', value: '{{sender.fromEmail}}' },
        { label: 'Reply Email', value: '{{sender.replyEmail}}' },
        { label: 'Company Name', value: '{{profile.companyName}}' },
        { label: 'Company Email', value: '{{profile.companyEmail}}' },
        { label: 'Company Phone', value: '{{profile.companyPhone}}' },
        { label: 'Company Address', value: '{{profile.companyAddress}}' },
        { label: 'Company Country', value: '{{profile.companyCountry}}' },
        { label: 'Company State', value: '{{profile.companyState}}' }
      ]
    },
    {
      heading: t('CONTACT'),
      variables: [
        { label: 'Email', value: '{{contact.email}}' },
        { label: 'Phone', value: '{{contact.phone}}' },
        { label: 'First Name', value: '{{contact.firstName}}' },
        { label: 'Last Name', value: '{{contact.lastName}}' },
        { label: 'Industry', value: '{{industry}}' },
        { label: 'Company', value: '{{company}}' },
        { label: 'Personalization', value: '{{contact.personalization}}' },
        { label: 'Personalization A', value: '{{contact.personalization_a}}' },
        { label: 'Personalization B', value: '{{contact.personalization_b}}' },
        { label: 'Personalization C', value: '{{contact.personalization_c}}' },
        { label: 'Personalization D', value: '{{contact.personalization_d}}' },
        { label: 'State', value: '{{contact.state}}' },
        { label: 'Country', value: '{{contact.country}}' }
      ]
    }
  ]

  const copyVariable = e => {
    navigator.clipboard.writeText(e.target.dataset.value)
    dispatch(fireSuccessToaster(t('EMAIL_VARIABLE_COPIED_MESSAGE')))
  }

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div className="p-6 flex space-x-4">
        {variables.map(item => (
          <div>
            <div className="text-gray-600 font-semibold mb-2 text-sm">
              {item.heading}
            </div>
            {item.variables.map(item => (
              <div
                onClick={copyVariable}
                data-value={item.value}
                className="text-sm p-2 hover:bg-gray-100 rounded cursor-pointer"
              >
                {item.label}
              </div>
            ))}
          </div>
        ))}
      </div>
    </CustomPopper>
  )
}

const StepCard = ({
  t,
  stepOrder,
  isActive,
  stepData,
  template,
  onClick,
  onDurationChange,
  onDeleteStep
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'rounded-xl border bg-white mb-4 overflow-hidden cursor-pointer',
        isActive && 'border-primary-main'
      )}
    >
      <div className="flex items-center justify-between border-b px-4 py-3">
        <div className="flex items-center text-primary-main">
          <EnvelopeIcon className="w-5 h-5" />
          <span className="ml-2 font-semibold">
            {t('STEP')} {stepOrder}
          </span>
        </div>

        <button
          onClick={onDeleteStep}
          className="px-2 py-2 border rounded hover:bg-gray-50 hover:text-red-500"
        >
          <TrashIcon />
        </button>
      </div>

      <div className="p-4">
        <span className="text-sm text-gray-500">{t('SUBJECT')}:</span>{' '}
        <span className="text-sm">
          {template?.subject || '< Empty Subject >'}
        </span>
      </div>

      <div className="flex items-center px-4 py-4 bg-primary-light text-sm">
        <ClockIcon className="w-4 h-4 mr-2" />
        <span>{t('WAIT_FOR')}</span>
        <div className="mx-2">
          <TextField
            type="number"
            value={stepData.delay.duration}
            onChange={onDurationChange}
            className="w-12"
          />
        </div>
        <span>
          {t('DAYS')}, <span className="lowercase">{t('THEN')}</span>
        </span>
      </div>
    </div>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="flex items-start justify-between">
      <Skeleton
        variant="rect"
        width={275}
        height={200}
        className="rounded-lg"
      />
      <Skeleton
        variant="rect"
        className="flex-1 rounded-lg ml-6"
        height={500}
      />
    </div>
  )
}

const modifyEmailBody = ({ campaignId, templateId, emailBody }) => {
  const rootUrl = `${process.env.REACT_APP_CONNECTION_URL}/campaignEvent/track/click?campaign=${campaignId}&template=${templateId}&contact={{contact}}&agencyOwner={{agencyOwner}}`

  const temp = document.createElement('div')
  temp.innerHTML = emailBody

  temp.querySelectorAll('a').forEach(elm => {
    if (!elm.href.includes(rootUrl))
      elm.setAttribute('href', `${rootUrl}&redirect=${elm.href}`)
  })

  return temp.innerHTML
}

export default ClientSignupEmailTemplate
